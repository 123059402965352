<template>
  <SkeletonLoading v-if="globalIsLoading" height="186px" borderRadius="25px"/>
  <div v-else class="standard-container-v2 manage-session">
    <!-- Session info -->
    <div class="browser-agent">
      {{ browserAgent }}
    </div>
    <div class="flex justify-between mt-n-3xl">
      <div class="font-semibold">{{ $t('date') }}</div>
      <div class="font-medium">{{ $moment(parseInt(timestamp)).format('MM/DD/YYYY hh:mm A') }}</div>
    </div>
    <div class="flex justify-between mt-n-lg w-full">
      <div class="font-semibold flex-shrink-0">{{ $t('ip_address') }}</div>
      <div class="ip-address">{{ ipAddress }}</div>
    </div>

    <!-- Button to end section if it is not the current session -->
    <div class="mt-n-3xl relative">
      <div v-if="isLoading" class="loading">
        <Loading :smallLoading="true"/>
      </div>
      <div v-else-if="isCurrentSession">
        {{ $t('manage_sessions.current') }}
      </div>
      <div
        v-else class="text-center text-text-negative cursor-pointer hover:opacity-80"
        @click="$emit('endSession', id)"
      >
        {{ $t('manage_sessions.end') }}
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from '@/components/misc';

export default {
  name: 'CurrentPassword',
  components: {
    Loading,
  },

  props: {
    browserAgent: String,
    id: String,
    ipAddress: String,
    isCurrentSession: Boolean,
    isLoading: Boolean,
    timestamp: String,
  }
};
</script>

<style scoped>
  .browser-agent {
    @apply flex items-center;
    min-height: 36px;
  }

  .manage-session {
    @apply text-n-md font-bold leading-sm;
    box-shadow: 0 4px 10px rgba(35, 42, 101, 0.2);
  }

  .ip-address {
    @apply font-medium ml-n-3xl;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }

  .loading {
    @apply text-text-negative;
    height: 18px;
  }
</style>
