<template>
  <LayoutMainPages>
    <div class="manage-sessions-container">
      <Title :title="$t('manage_sessions.title')" />
      <ManageSession
        class="mt-n-4xl"
        @endSession="promptEndSession"
        v-for="(n, index) in data.length"
        v-bind:key="index"
        :browserAgent="data[index]['browser_agent']"
        :id="data[index]['id']"
        :ipAddress="data[index]['current_ip']"
        :isCurrentSession="!!data[index]['current']"
        :timestamp="data[index]['time_added']"
        :isLoading="isLoading && data[index]['id'] === selectedSessionId"
      />
    </div>
  </LayoutMainPages>
</template>

<script>
import { ManageSession } from '@/components/security';
import { LayoutMainPages, Title } from '@/components/misc';
import LOGOUT_SESSION from '@/graphql/mutations/LogoutSession.gql';
import MANAGE_SESSIONS from '@/graphql/queries/ManageSessions.gql';

export default {
  name: 'ManageSessions',
  components: {
    LayoutMainPages,
    ManageSession,
    Title,
  },

  computed: {
    data() {
      return this.lodashGet(this.$store.state.api, 'current_user.get_all_sessions', new Array(6).fill(0));
    }
  },

  data() {
    return {
      isLoading: false,
      selectedSessionId: null,
    };
  },

  methods: {
    async endSession() {
      try {
        this.isLoading = true;
        await this.apolloApiCall({
          mutation: LOGOUT_SESSION,
          variables: {
            id: this.selectedSessionId,
          },
        });
        this.showSuccess(this.$t('manage_sessions.success'));
      } catch (err) {
        await this.showError(err);
      } finally {
        await this.$store.dispatch('api/refreshPageData', { $apollo: this.$apollo, query: MANAGE_SESSIONS });
        this.isLoading = false;
      }
    },

    promptEndSession(id) {
      this.selectedSessionId = id;
      this.$store.commit('ui/showPrompt', {
        type: 'destructive',
        eventName: 'confirm-end-session',
        title: this.$t('manage_sessions.end'),
        message: this.$t('manage_sessions.confirm')
      });
    }
  },

  mounted() {
    this.$root.$on('confirm-end-session', () => {
      this.endSession();
    });
  },

  beforeDestroy() {
    this.$root.$off('confirm-end-session');
  },
};
</script>

<style scoped>
  .manage-sessions-container {
    width: 540px;
    max-width: 100%;
  }
</style>
